<template>
  <div class="case-team">
    <el-table :data="tableData" style="width: 100%">
      <el-table-column prop="relatedname" :label="$t('c1077')">
      </el-table-column>
      <el-table-column prop="rolename" :label="$t('c1076')"> </el-table-column>
      <el-table-column prop="accesslevelname" :label="$t('c1070')">
      </el-table-column>
      <el-table-column
        prop="lastmodifybyidccname"
        :label="$t('label.lastmodifyby')"
      >
        <template slot-scope="scope">
          {{ scope.row.lastmodifybyidccname }}
        </template>
      </el-table-column>
      <el-table-column
        fixed="right"
        :label="$t('label.ems.action')"
        width="100"
        v-if="detailType != 'printDetail'"
      >
        <template slot-scope="scope">
          <el-button
            @click="handleClick(scope.row)"
            style="color: #006dcc"
            type="text"
            size="small"
          >
            {{ $t("label.delete") }}
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import * as Api from "./api";
export default {
  name: "caseteam",
  props: {
    // 记录id
    recordId: {
      type: String,
      default: "",
    },
    // 详情页类型 (printDetail代表是打印页的详情页,detail代表详情页)
    detailType: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      // 个案团队数据
      tableData: [],
    };
  },
  mounted() {
    this.init();
    this.$Bus.$on("freshCaseTeam", () => {
      this.init();
    });
  },
  watch: {},
  methods: {
    /**
     * 初始化
     */
    init() {
      Api.queryCaseTeamMember({
        id: this.$route.params.id || this.recordId,
      }).then((res) => {
        this.tableData = res.data.memberlist || [];
      });
    },
    /**
     * 删除成员
     */
    handleClick(item) {
      this.$confirm(
        this.$i18n.t("label.sure.delete") + "?",
        this.$i18n.t("label.popup.infoz"),
        {
          confirmButtonText: this.$i18n.t("label.confirm"),
          cancelButtonText: this.$i18n.t("label.cancel"),
          type: "warning",
        }
      ).then(() => {
        Api.deleteCaseTeamMember({
          id: item.id,
        }).then((res) => {
          if (res.result) {
            this.$message({
              type: "success",
              message: this.$i18n.t("label.ems.delete.success"),
            });
            this.init();
          }
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-table {
  font-size: 12px;
}

::v-deep .el-table thead .el-table__cell {
  padding: 6px 0;
  background: #fafaf9;
  color: #666666;
  border-top: 1px solid #e1e1e1;
  border-right: 1px solid #dedcda;
}
::v-deep .el-table tbody .el-table__cell {
  padding: 2px 0;
}
</style>