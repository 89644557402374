import axios from "@/config/httpConfig";

// 获取预定义个案团队列表
export function queryCaseTeamList(args) {
  return axios.post("caseteam/queryCaseTeamList", args);
}
// 查询个案团队成员列表
export function queryCaseTeamMember(args) {
  return axios.post("caseteam/queryCaseTeamMember", args);
}
// 删除个案团队成员
export function deleteCaseTeamMember(args) {
  return axios.post("caseteam/deleteCaseTeamMember", args);
}