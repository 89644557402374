<template>
  <div ref="printTest" class="printTest">
    <span class="print_btn" @click="printBtn">
      {{ $t("label.print") }}
    </span>
    <div>
      <div class="tit">
        <p class="tit_p">{{ tabName }}</p>
        <span>{{ bigTitle[0] ? bigTitle[0].fieldValue : "" }}</span>
      </div>
      <info-form
        class="info_box"
        ref="infoForm"
        v-if="!loadingDetail"
        :objId="objId"
        :operation="operation"
        :option-list="optionList"
        :inlineedit="inlineedit"
        :is-edit="isEdit"
        :canEditOwner="canEditOwner"
        :form-attr="formAttr"
        :label-position="labelPosition"
        :label-width="labelWidth"
        :status-icon="statusIcon"
        :objectApi="objectApi"
      >
      </info-form>
      <div v-for="(each, index) in objArr" :key="index" class="print_table">
        <p>{{ each.objLabel }}</p>
        <!-- 批准历史 -->
        <div
          v-if="
            historyData.length !== 0 &&
            each.objName === 'tp_sys_approval_instance_his'
          "
        >
          <table style="width: 100%" bordercolor="#DDDBDA">
            <tr>
              <!-- 日期 -->
              <th>{{ $t("date") }}</th>
              <!-- 状态 -->
              <th>{{ $t("label.state") }}</th>
              <!-- 被分配人 -->
              <th>{{ $t("label.assigned") }}</th>
              <!-- 实际批准人 -->
              <th>{{ $t("label.layout.realityapprover") }}</th>
              <!-- 注释 -->
              <th>{{ $t("label.note") }}</th>
              <!-- 整体状态 -->
              <th>{{ $t("label.wholestate") }}</th>
            </tr>
            <tbody v-for="(item, index1) in historyData" :key="index1">
              <tr>
                <!-- 步骤 -->
                <td colspan="5" class="tit">
                  {{ $t("step") }}:{{ item.stepName }}
                </td>
                <td class="tit">
                  <!-- 已提交 -->
                  <b v-if="item.status === 'Started'">{{
                    $t("label.submitted")
                  }}</b>
                  <!-- 已批准 -->
                  <b v-else-if="item.status === 'Approved'">{{
                    $t("label.approved")
                  }}</b>
                  <!-- 待处理 -->
                  <b
                    v-else-if="
                      item.status === 'Pending' || item.status === 'pending'
                    "
                    >{{ $t("label.pending") }}</b
                  >
                  <!-- 已调回 -->
                  <b v-else-if="item.status === 'Recalled'">{{
                    $t("lebel.alreadyback")
                  }}</b>
                  <!-- 已拒绝 -->
                  <b v-else-if="item.status === 'Rejected'">{{
                    $t("label.refused")
                  }}</b>
                </td>
              </tr>
              <tr v-for="(val, i) in item.instancehis" :key="i">
                <td>{{ val.lastModifyDate }}</td>
                <td>
                  <!-- 已提交 -->
                  <span v-if="val.stepStatus === 'Started'">{{
                    $t("label.submitted")
                  }}</span>
                  <!-- 已批准 -->
                  <span v-else-if="val.stepStatus === 'Approved'">{{
                    $t("label.approved")
                  }}</span>
                  <!-- 待处理 -->
                  <span v-else-if="val.stepStatus === 'Pending'">{{
                    $t("label.pending")
                  }}</span>
                  <!-- 已调回 -->
                  <span v-else-if="val.stepStatus === 'Recalled'">{{
                    $t("label.pending")
                  }}</span>
                  <!-- 已拒绝 -->
                  <span v-else-if="val.stepStatus === 'Rejected'">{{
                    $t("label.refused")
                  }}</span>
                </td>
                <td>{{ val.assigntoName }}</td>
                <td>{{ val.actualName }}</td>
                <td>{{ val.comments }}</td>
                <td>
                  <span></span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- 价格手册 -->
        <div
          v-if="
            pricebookArr.length > 0 &&
            each.objLabel === $t('lable.opppdt.selPbk.pricebook')
          "
        >
          <table border="1" style="width: 100%" bordercolor="#DDDBDA">
            <tr>
              <!-- 价格手册名称 -->
              <th>{{ $t("label.product.pricebooks.name") }}</th>
              <!-- 价目表价格 -->
              <th>{{ $t("lable.product.plist") }}</th>
              <!-- 使用标准价格 -->
              <th>{{ $t("lable.product.pricebooks.ustand") }}</th>
              <!-- 已启用 -->
              <th>{{ $t("label.inusing") }}</th>
            </tr>
            <tr v-for="(item, index1) in pricebookArr" :key="index1">
              <td>{{item.pricebookname}}</td>
              <td>{{ item.listPrice && parseInt(item.listPrice).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,") }}{{ item.currency }}</td>
              <td>
                <el-checkbox
                  :value="item.useStdPrice === 'true' ? true : false"
                  disabled
                ></el-checkbox>
              </td>
              <td>
                <el-checkbox
                  :value="item.isenable === 'true' ? true : false"
                  disabled
                ></el-checkbox>
              </td>
            </tr>
          </table>
        </div>
        <!-- 个案团队 -->
        <div
          v-if="
            caseTeamMemberArr.length > 0 &&
            each.objLabel === $t('c1080')
          "
        >
          <caseteam :recordId="id" detailType="printDetail"></caseteam>
        </div>
        <div
          v-if="
            each.data &&
            each.data.length !== 0 &&
            each.objName !== 'tp_sys_approval_instance_his' &&
            !each.data[0].norecord
          "
        >
          <!-- 其他-->
          <table border="1" style="width: 100%" bordercolor="#DDDBDA">
            <tr>
              <th v-for="(val, ind) in each.fieldList" style="min-width: 90px" :key="ind">
                {{ val.label }}
              </th>
            </tr>
            <tr v-for="(item1, index1) in each.data" :key="index1">
              <td v-for="(val, ind) in each.fieldList" :key="ind">
                <span
                  v-if="
                    val.type === 'Y' ||
                    val.type === 'L' ||
                    val.type === 'Q' ||
                    val.type === 'MR' ||
                    val.type === 'M' ||
                    val.type === 'R'
                  "
                  >{{
                    item1[val.name + "ccname"]
                      ? item1[val.name + "ccname"]
                      : item1[val.name]
                  }}</span
                >
                <span v-else-if="val.type === 'B'">
                  <el-checkbox
                    :value="item1[val.name] === 'true' || item1[val.name] === 1"
                    disabled
                  ></el-checkbox
                ></span>
                <span v-else-if="val.type === 'D'">
                  {{ item1[val.name] | dateFormat(countryCode) }}
                </span>
                <span v-else-if="val.type === 'F'">
                  {{ item1[val.name] | datetimeFormat(countryCode) }}
                </span>
                <!-- 图片-->
                <span v-else-if="val.type === 'IMG'" class="cannotEdit">
                  <img
                    v-if="
                      item1[val.name] !== undefined &&
                      item1[val.name] !== '' &&
                      item1[val.name] !== null
                    "
                    :src="imgSrc(item1[val.name])"
                  />
                </span>
                <span v-else-if="val.type === 'SCORE'" class="cannotEdit stars">
                  <span
                    v-for="(star, idx) in Number(val.length) + 1"
                    v-show="idx !== 0"
                    :class="{
                      show2: idx <= Number(item1[val.name]),
                    }"
                    :key="idx"
                  >
                  </span>
                </span>
                <span
                  v-else-if="val.name === 'operate' || val.name === 'clxx'"
                  v-html="item1[val.name]"
                ></span>
                <span v-else>{{ item1[val.name] }}</span>
              </td>
            </tr>
          </table>
        </div>
        <div
          v-if="
            (each.data &&
              each.data.length == 0 &&
              each.objName !== 'tp_sys_approval_instance_his') ||
            (each.data && each.data[0].norecord)
          "
          class="noth_box"
        >
          <!-- 没有记录 -->
          {{ $t("label.norecord") }}
        </div>
        <div
          v-if="
            each.objName === 'tp_sys_approval_instance_his' &&
            historyData.length === 0
          "
          class="noth_box"
        >
          <!-- 没有记录 -->
          {{ $t("label.norecord") }}
        </div>
        <!-- 价格手册没有记录 -->
        <div
          v-if="
            each.objLabel === $t('lable.opppdt.selPbk.pricebook') &&
            pricebookArr.length === 0
          "
          class="noth_box"
        >
          <!-- 没有记录 -->
          {{ $t("label.norecord") }}
        </div>
        <!-- 个案团队没有记录 -->
        <div
          v-if="
            each.objLabel === $t('c1080') &&
            caseTeamMemberArr.length === 0
          "
          class="noth_box"
        >
          <!-- 没有记录 -->
          {{ $t("label.norecord") }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import INPUTTYPE from "@/config/enumCode/inputType";
import * as CommonObjApi from "./api";
import { getApprovalHistoryList } from "./api.js";
import InfoForm from "@/components/Form/info-form.vue";
import * as SetViewApi from "./api";
import waterMark from "@/utils/waterMark/callMark.js";
import * as Time from "@/utils/time";
import caseteam from "@/views/commonObjects/components/caseTeam/caseteam.vue"

export default {
  name: "printDetail",
  components: { InfoForm, caseteam },
  data() {
    return {
      id: this.$route.params.id,
      prefix: this.$route.params.prefix,
      pricebookArr: [],
      // 个案团队
      caseTeamMemberArr: [],
      objectApi: "",
      objId: "",
      loadingDetail: false,
      operation: "DETAIL",
      optionList: {},
      controlContainer: null, //依赖关系
      actionRequireds: [], // 动态布局必填字段
      actionReadonlys: [], // 动态布局只读字段
      actionShowFields: [], // 动态布局显示字段
      actionShowSections: [], // 动态布局显示部分
      labelPosition: "right",
      formAttr: null,
      labelWidth: 200,
      autoRefresh: true,
      inlineedit: false,
      isEdit: false,
      canEditOwner: false,
      statusIcon: false,
      showDetail: false,
      historyData: [],
      perPage: "1500",
      objArr: [],
      diogHeight: 0,
      list: [],
      bigTitle: [],
      tabName: "",
      dateFormat: Time.dateFormat(this.$cookies.get("countryCode")),
      datetimeFormat: Time.datetimeFormat(this.$cookies.get("countryCode")),
      timeFormat: Time.timeFormat(this.$cookies.get("countryCode")),
      countryCode: this.$cookies.get("countryCode"),
      token: this.$CCDK.CCToken.getToken(),
    };
  },
  created() {
    this.init();
  },
  mounted() {
    document.getElementById("lightning-loading").style.display = "none";
    let element = document.getElementById("1default");
    if (element) {
      element.style.display = "none";
    }
  },
  methods: {
    printBtn() {
      window.print();
    },
    init() {
      getApprovalHistoryList({ relatedId: this.id }).then((res) => {
        this.historyData = res.data;
      });
      this.operation = "DETAIL";
      this.getFormAttr();
      SetViewApi.GetBrief({ id: this.$route.params.id }).then((res) => {
        this.bigTitle = res.data.title;
        this.tabName = res.data.label;
        document.title = this.$setTitle(
          `${this.tabName}——${
            this.bigTitle[0] ? this.bigTitle[0].fieldValue : ""
          }`,
          false
        );
      });
      this.getRelationList();
    },
    getRelationList() {
      this.list = [];
      // 产品 价格手册
      if (this.prefix === "009") {
        this.list.push({
          relationName: this.$i18n.t("lable.opppdt.selPbk.pricebook"),
          relatedlistId: "pricebook",
          relationSize: 0,
        });
        CommonObjApi.queryProductPricebookItems({ productId: this.id }).then(
          (res) => {
            this.pricebookArr = res.data.pricebookItems;
          }
        );
      } else if (this.prefix === "006") {
        // 市场活动 影响业务机会
        // this.list.push({
        //   relationName: '影响业务机会',
        //   relatedlistId: 'influenceBusinessOpportunities',
        //   relationSize: 0
        // })
      } else if(this.prefix === "008") {
        // 个案团队
        this.list.push({
          relationName: this.$i18n.t("c1080"),
          relatedlistId: "caseteam",
          relationSize: 0,
        });
        SetViewApi.queryCaseTeamMember({ id: this.id }).then((res)=> {
          this.caseTeamMemberArr = res.data.memberlist;
        })
      }
      SetViewApi.GetRelationList({ recordId: this.id }).then((res) => {
        if (res.result && res.returnCode === "1") {
          let data = [];
          data = res.data.filter(
            // "备注"
            (item) =>
              item.relatedlistType != "activityHistory" &&
              item.relatedlistType != "openActivities" &&
              item.relationName !=
                this.$i18n.t("label.weixin.group.description") &&
              item.relatedlistType != "campaign" &&
              item.relatedlistType != "caseteam"
          );
          this.list = this.list.concat(data);
          this.getDetailRelatedItems();
        } else {
          this.$message.error(res.returnInfo);
        }
      });
    },
    async getDetailRelatedItems() {
      this.objArr = [];
      this.diogHeight = 0;
      for (let i = 0; i < this.list.length; i++) {
        if (this.list[i].relatedlistId !== "pricebook" && this.list[i].relatedlistId !== "caseteam") {
          await this.relatedItemsData(this.list[i]);
        }
      }
      if (this.prefix === "009") {
        this.objArr.unshift({
          objLabel: this.$i18n.t("lable.opppdt.selPbk.pricebook"),
        });
      } else if (this.prefix === "008") {
        // 个案团队
        this.objArr.unshift({
          objLabel: this.$i18n.t("c1080"),
        });
      }
    },
    async relatedItemsData(val) {
      let params = {
        recordId: this.$route.params.id || this.id,
        layoutId: val.layoutId ? val.layoutId : "",
        relatedlistId: val.relatedlistId ? val.relatedlistId : "",
        perPage: this.perPage,
        attachementType: val.attachementType ? val.attachementType : "",
      };
      await SetViewApi.GetDetailRelatedItems(params).then((res) => {
        if (res.result && res.returnCode === "1") {
          // 手动添加totalCount属性
          if(res.data && res.data.data){
            if(res.data.data[0] && res.data.data[0].norecord){
              res.data.totalCount = 0
            }else{
              res.data.totalCount = res.data.data.length
            }
          }else{
            res.data.totalCount = 0
          }
          if (res.data !== null) {
            this.objArr.push(res.data);
            // 
            // 打印页面加水印
            this.diogHeight =
              Number(
                document.getElementsByClassName("printTest")[0].clientHeight
              ) + 150;
            if (
              localStorage.getItem("openWater") == "true" &&
              document.getElementsByClassName("printTest")[0] &&
              this.$route.path.split("/")[1] === "print-detail"
            ) {
              waterMark(
                this.$refs.printTest,
                document.getElementsByClassName("printTest")[0].clientWidth +
                  "px",
                this.diogHeight + "px",
                "999999",
                "detail"
              );
            }
          }
        } else {
          this.$message.error(res.returnInfo);
        }
      });
    },
    closeDialog() {
      this.$emit("closeDialog");
    },
    getFormAttr() {
      this.diogHeight = 0;
      this.loadingDetail = true;
      this.formAttr = [];
      this.optionList = {};
      CommonObjApi.getDetail({
        id: this.$route.params.id || this.id,
        objectApi: "",
        operation: this.operation,
        recordType: this.recordType,
      }).then((res) => {
        this.objId = res.data.objId;
        this.objectApi = res.data.objectApi;

        res.data.dataList.forEach((group) => {
          let colNum = group.colNum;
          if (group.showWhenEdit) {
            this.formAttr.push({
              width: "100%",
              type: "groupTitle",
              title: group.title,
              selectionId: group.selectionId,
              groupShow: true,
              hidden: false,
            });
          }

          group.data &&
            group.data.length > 0 &&
            group.data.forEach((line) => {
              if (
                line.left && !(Array.isArray(line.left) && line.left.length === 0) &&
                line.left.fieldType !== "blank"
              ) {
                let attr = this.formAttr.find((item) => {
                  return item.fieldId === line.left.fieldId;
                });
                if (attr === undefined) {
                  this.addFormAttr(group, colNum, line.left, undefined, "left");
                }
              } else {
                if (line.left.fieldType == "blank") {
                  this.formAttr.push({
                    width: "50%",
                    type: "noData",
                    hidden: false,
                    groupShow: true,
                  });
                }
              }
              if (colNum !== 1) {
                if (
                  !(Array.isArray(line.right) && line.right.length === 0) &&
                  line.right.fieldType !== "blank"
                ) {
                  let attr = this.formAttr.find((item) => {
                    return item.fieldId === line.right.fieldId;
                  });
                  if (attr === undefined) {
                    this.addFormAttr(
                      group,
                      colNum,
                      line.right,
                      undefined,
                      "right"
                    );
                  }
                } else {
                  this.attrIndex += group.colNum === 1 ? 0 : 1;
                  this.formAttr.push({
                    width: "50%",
                    colNum: 2,
                    attrIndex: this.attrIndex,
                    type: "noData",
                    hidden: false,
                    groupShow: true,
                  });
                }
              }
            });
        });
        this.diogHeight +=
          Number(document.getElementsByClassName("printTest")[0].clientHeight) +
          150;
        // 打印页面加水印
        if (
          localStorage.getItem("openWater") == "true" &&
          document.getElementsByClassName("printTest")[0] &&
          this.$route.path.split("/")[1] === "print-detail"
        ) {
          waterMark(
            this.$refs.printTest,
            document.getElementsByClassName("printTest")[0].clientWidth + "px",
            this.diogHeight + "px",
            "999999",
            "detail"
          );
        }
        this.loadingDetail = false;

        this.$nextTick(() => {
          // 强制刷新表单
          this.$refs.infoForm && this.$refs.infoForm.filterFormData();

          // 查找带值，绑定事件
          if (
            res.data.lookupValueExpression !== undefined &&
            res.data.lookupValueExpression !== "" &&
            res.data.lookupValueExpression !== null
          ) {
            this.setLookupValue(JSON.parse(res.data.lookupValueExpression));
          }
          // 依赖关系，绑定事件
          if(res.data && res.data.controlContainer){
            this.controlContainer = JSON.parse(res.data.controlContainer);
            this.setControlContainer();
          }
          // 动态布局规则，绑定事件
          this.getDynamicLayoutListDetail(res.data.layoutId);

          this.formAttr.forEach((res) => {
            if (res.apiname && res.apiname == "ownerid") {
              this.$Bus.$emit("deliver-group-strategy-ownerid", res.data[0]);
            }
          });
        });
      });
    },
    // 查找带值字段
    setLookupValue(lookupValueExpression) {
      this.lookupValues = [];
      lookupValueExpression.forEach((item) => {
        this.formAttr.forEach((attr) => {
          if (attr.apiname === item.expression.split(",")[0].toLowerCase()) {
            attr.changeEvent =
              attr.changeEvent === undefined
                ? "lookupValue"
                : `${attr.changeEvent},lookupValue`;
            // 初始化规则时走规则
            if (attr.value !== undefined) {
              // 新建时必须修改
              // 编辑时，如果要带值的字段值为空，带的值必须带过去，如果值不为空，iscover为‘true’时才带值，要带值的字段有可能是查找字段，需要处理
              this.getLookupValue(item, attr.value);
            }
          }
        });
        this.lookupValues.push(item);
      });
    },
    // 查找带值
    lookupValue(selectVal, selectItem, formData) {
      this.lookupValues.forEach((lookupValue) => {
        if (
          lookupValue.expression.split(",")[0].toLowerCase() ===
          selectItem.apiname
        ) {
          this.getLookupValue(lookupValue, selectVal, formData);
        }
      });
    },
    // 查找带值
    getLookupValue(lookupRule, id, formData) {
      // id: 查找记录的id
      let fieldJson = [];
      let carryValue = "";

      fieldJson.push({
        fieldrelateid: lookupRule.id,
        id: id,
        expression: lookupRule.expression,
        iscover: lookupRule.iscover,
      });
      let param = {
        objId: this.prefix,
        fieldJson: JSON.stringify(fieldJson),
      };
      CommonObjApi.getLookupRelatedFieldValue(param).then((res) => {
        carryValue = res.data && res.data.length > 0 ? res.data[0].value : null;
        this.formAttr.forEach((attr) => {
          if (attr.fieldId === lookupRule.id) {
            if (formData !== undefined) {
              // 如果要带值的字段值为空，带的值必须带过去   如果值不为空，iscover为‘true’时才带值
              if (
                attr.value === "" ||
                attr.value === null ||
                (Array.isArray(attr.value) && attr.value.length === 0) ||
                lookupRule.iscover === "true"
              ) {
                // 查找、查找多选字段特殊处理
                if (
                  (attr.fieldType === "Y" || attr.fieldType === "MR") &&
                  res.data[0].value &&
                  res.data[0].lkid &&
                  res.data[0].value !== "" &&
                  res.data[0].lkid !== ""
                ) {
                  this.optionList[attr.prop] = [
                    {
                      value: res.data[0].lkid,
                      label: res.data[0].value,
                    },
                  ];
                  if (res.data[0].lkid !== "") {
                    attr.value =
                      attr.fieldType === "Y"
                        ? res.data[0].lkid
                        : [res.data[0].lkid];
                  }
                } else {
                  attr.value = carryValue;
                }
              }
            } else {
              // 如果要带值的字段值为空，带的值必须带过去   如果值不为空，iscover为‘true’时才带值
              if (
                formData[attr.prop] === "" ||
                formData[attr.prop] === null ||
                (Array.isArray(formData[attr.prop]) &&
                  formData[attr.prop].length === 0) ||
                lookupRule.iscover === "true"
              ) {
                // 查找、查找多选字段特殊处理
                if (
                  (attr.fieldType === "Y" || attr.fieldType === "MR") &&
                  res.data[0].value &&
                  res.data[0].lkid &&
                  res.data[0].value !== "" &&
                  res.data[0].lkid !== ""
                ) {
                  this.optionList[attr.prop] = [
                    {
                      value: res.data[0].lkid,
                      label: res.data[0].value,
                    },
                  ];
                  if (res.data[0].lkid !== "") {
                    formData[attr.prop] =
                      attr.fieldType === "Y"
                        ? res.data[0].lkid
                        : [res.data[0].lkid];
                  }
                } else {
                  formData[attr.prop] = carryValue;
                }
              }
            }
          }
        });
      });
    },
    // 改变查找/查找带值选项及值
    changeSelect(row, fieldId, formData) {
      let options = [];
      let fieldVal = [];
      if (row instanceof Array) {
        row.forEach((ele) => {
          options.push({
            label: ele.name,
            value: ele.id,
          });
        });
      } else {
        options.push({
          label: row.data.name,
          value: row.data.id,
        });
      }

      this.formAttr.forEach((attr) => {
        if (attr.fieldId === fieldId) {
          // 防止清空时清空选项，再次编辑查找字段时显示数据Id
          if (options.length > 0) {
            this.$set(this.optionList, attr.prop, options);
          }
          if (attr.fieldType === "MR") {
            options.forEach((option) => {
              fieldVal.push(option.value);
            });
            formData[attr.apiname] = fieldVal;
          } else {
            formData[attr.apiname] = row.data === undefined ? "" : row.data.id;
          }

          // 若有查找带值，将值带入
          this.lookupValues.forEach((lookupValue) => {
            if (
              attr.apiname ===
              lookupValue.expression.split(",")[0].toLowerCase()
            ) {
              this.getLookupValue(lookupValue, "", formData);
            }
          });
        }
      });
    },
    // 依赖关系
    setControlContainer() {
      // 先将被控字段选项清空,循环后有对应选项再进行赋值
      this.controlContainer.forEach((control) => {
        control.controlVals.forEach((vals) => {
          vals.dependFields.forEach((dependField) => {
            this.formAttr.forEach((controlAttr) => {
              if (controlAttr.fieldId === dependField.dependfieldid) {
                this.optionList[controlAttr.prop] = [];
              }
            });
          });
        });
      });

      this.controlContainer.forEach((control) => {
        // 控制字段
        this.formAttr.forEach((attr) => {
          if (attr.fieldId === control.controlfield) {
            this.control(
              attr.value,
              attr.fieldId,
              this.$refs.infoForm.formData,
              true
            );
          }
        });
      });
    },
    // 依赖字段 ,initControl为true时是新建初始化，已经将选项值都清空，无需再次清理
    control(selectVal, fieldId, formData, initControl = false) {
      // 被控制字段集合
      let controledFieldId = [];
      let controledFieldProp = [];

      // 查找对应规则
      let control = this.controlContainer.find((control) => {
        return control.controlfield === fieldId;
      });

      if (control !== undefined) {
        // 先将被控字段选项清空,循环后有对应选项再进行赋值
        if (!initControl) {
          control.controlVals.forEach((vals) => {
            vals.dependFields.forEach((dependField) => {
              this.formAttr.forEach((controlAttr) => {
                if (controlAttr.fieldId === dependField.dependfieldid) {
                  controledFieldId.push(controlAttr.fieldId);
                  controledFieldProp.push(controlAttr.prop);
                  this.$set(this.optionList, controlAttr.prop, []);
                }
              });
            });
          });
        }

        // 查找当前值是否有控制规则
        let controlCondition = control.controlVals.find((item) => {
          return item.val === selectVal;
        });
        if (controlCondition === undefined) {
          controledFieldId.forEach((fieldId) => {
            this.formAttr.forEach((controlAttr) => {
              if (controlAttr.fieldId === fieldId) {
                // 清空被控字段值并递归
                if (controlAttr.fieldType === "Q") {
                  this.$set(formData, controlAttr.apiname, []);
                } else {
                  this.$set(formData, controlAttr.apiname, "");
                }
                this.control(formData[controlAttr.apiname], fieldId, formData);
              }
            });
          });
        } else {
          // 控制选项列表和选项值
          controlCondition.dependFields.forEach((dependField) => {
            this.formAttr.forEach((controlAttr) => {
              if (controlAttr.fieldId === dependField.dependfieldid) {
                let options = [];
                dependField.vals.forEach((element) => {
                  options.push({
                    key: element.deflangval,
                    val: element.val,
                  });
                });
                this.optionList[controlAttr.prop] = options;

                // 若当前选中值不在选项列表中，则清空
                let option = options.find((item) => {
                  return item.val === formData[controlAttr.apiname];
                });
                if (option === undefined) {
                  // 清空被控字段值并递归
                  if (controlAttr.fieldType === "Q") {
                    this.$set(formData, controlAttr.apiname, []);
                  } else {
                    this.$set(formData, controlAttr.apiname, "");
                  }
                  // 如果被控制字段选项中包含字段默认值，将依赖字段的值赋为默认值
                  if (
                    controlAttr.defaultValue &&
                    controlAttr.defaultValue !== ""
                  ) {
                    let option = options.find((item) => {
                      return item.val === controlAttr.defaultValue;
                    });
                    if (option !== undefined) {
                      this.$set(
                        formData,
                        controlAttr.apiname,
                        controlAttr.fieldType === "Q"
                          ? [controlAttr.defaultValue]
                          : controlAttr.defaultValue
                      );
                    }
                  }
                  this.control(
                    formData[controlAttr.apiname],
                    dependField.dependfieldid,
                    formData
                  );
                }
              }
            });
          });
        }

      }
    },
    // 动态布局规则
    getDynamicLayoutListDetail(layoutId) {
      CommonObjApi.getDynamicLayoutListDetail({
        layoutId: layoutId,
      }).then((res) => {
        // 清空动态布局规则
        this.actionRequireds = [];
        this.actionShowFields = [];
        this.actionShowSections = [];
        this.actionReadonlys = [];
        this.actionConditions = res.data;

        res.data.forEach((action) => {
          // 重置规则控制字段
          action.mainConditions.forEach((mainCondition) => {
            // 主规则
            this.handleCondition(mainCondition, false);

            this.formAttr.forEach((attr, idx) => {
              // 地址和地理定位字段动态布局返回的是父字段id
              let fieldId = attr.fieldId;
              if (attr.parentType === "AD" || attr.parentType === "LT") {
                fieldId = attr.parentId;
              }
              if (fieldId === mainCondition.fieldId) {
                let changeEvent =
                  attr.changeEvent === undefined
                    ? "handleAction"
                    : `${attr.changeEvent},handleAction`;
                this.$set(this.formAttr[idx], "changeEvent", changeEvent);
              }
            });

            // 二级规则
            mainCondition.dynamicPageConditions &&
              mainCondition.dynamicPageConditions.forEach((dynamicPage) => {
                this.handleCondition(dynamicPage, false);

                dynamicPage.twoLevelConditions.forEach((twoLevelCondition) => {
                  this.formAttr.forEach((attr, idx) => {
                    // 地址和地理定位字段动态布局返回的是父字段id
                    let fieldIdTwo = attr.fieldId;
                    if (attr.parentType === "AD" || attr.parentType === "LT") {
                      fieldIdTwo = attr.parentId;
                    }
                    if (fieldIdTwo === twoLevelCondition.fieldId) {
                      let changeEvent =
                        attr.changeEvent === undefined
                          ? "handleAction"
                          : `${attr.changeEvent},handleAction`;
                      this.$set(this.formAttr[idx], "changeEvent", changeEvent);
                    }
                  });
                });
              });
          });
        });

        // 走规则
        this.handleAction();
      });
    },
    // 重置规则控制字段
    handleCondition(condition, flag) {
      // 设置字段/部分 是否显示/必填 注：部分下有必填字段时不隐藏该部分
      // 当flag为真时，规则中只要有一个条件满足，该字段显示/必填就为真
      // 每个控制字段变化时所有规则重新走一遍
      if (flag) {
        if (condition.action_requireds) {
          condition.action_requireds.forEach((requiredField) => {
            //  如果动态布局不是只读的，判断该字段动态布局是否是必填的，需要结合动态布局是否是必填的，如果是必填的，那么就是必填，只读为false。动态布局优先级永远是大于原布局
            this.actionRequireds.forEach((item) => {
              if (item.fieldId === requiredField.field_id) {
                item.required = item.required || flag;
              }
            });
          });
        }
        if (condition.action_readonlys) {
          condition.action_readonlys.forEach((readonlysField) => {
            this.actionReadonlys.forEach((item) => {
              if (item.fieldId === readonlysField.field_id) {
                item.readonlys = item.readonlys || flag;
              }
            });
          });
        }
        if (condition.action_showFields) {
          condition.action_showFields.forEach((requiredField) => {
            this.actionShowFields.forEach((item) => {
              if (item.fieldId === requiredField.field_id) {
                item.show = item.show || flag;
              }
            });
          });
        }
        if (condition.action_showSections) {
          condition.action_showSections.forEach((requiredField) => {
            this.actionShowSections.forEach((item) => {
              if (item.selectionId === requiredField.section_id) {
                item.showSection = item.showSection || flag;
              }
            });
          });
        }
      } else {
        // 初始化需要去重
        // 字段显示与否
        if (condition.action_showFields) {
          let showFields = [];
          condition.action_showFields.forEach((requiredField) => {
            if (!showFields.includes(requiredField.field_id)) {
              showFields.push(requiredField.field_id);
              this.actionShowFields.push({
                fieldId: requiredField.field_id,
                show: flag,
              });
            }
          });
        }
        // 部分显示与否
        if (condition.action_showSections) {
          let showSections = [];
          condition.action_showSections.forEach((requiredField) => {
            if (!showSections.includes(requiredField.section_id)) {
              showSections.push(requiredField.section_id);
              this.actionShowSections.push({
                sectionName: requiredField.sectionName,
                selectionId: requiredField.section_id,
                showSection: flag,
              });
            }
          });
        }
        // 字段必填与否
        if (condition.action_requireds) {
          let requireds = [];
          condition.action_requireds.forEach((requiredField) => {
            if (!requireds.includes(requiredField.field_id)) {
              requireds.push(requiredField.field_id);
              this.actionRequireds.push({
                fieldId: requiredField.field_id,
                required: flag,
              });
            }
          });
        }
        // 只读字段

        if (condition.action_readonlys) {
          let readonlys = [];
          condition.action_readonlys.forEach((readonlysField) => {
            if (!readonlys.includes(readonlysField.field_id)) {
              readonlys.push(readonlysField.field_id);
              this.actionReadonlys.push({
                fieldId: readonlysField.field_id,
                readonlys: flag,
              });
            }
          });
        }
      }
    },
    /**
     * 判断高级条件是否成立
     * @param {Array} booleanList:条件成立结果组成的数组
     * @param {String} label:高级条件的label，表示高级条件的组合关系
     * 
     */
     isJudgment(booleanList,label){
      let isTrue = false
      if(label){
        // 去掉1 and 
        label = label.slice(5)
        // 替换and和or
        label = label.replace(/and/g,'&&')
        label = label.replace(/or/g,'||')
        // 替换每个条件的执行
        booleanList.forEach((item,index) => {
          let labelIndex = new RegExp((index+2).toString())
          label = label.replace(labelIndex,item)
        })
        isTrue = eval(label)
      }
      return isTrue
    },
    // 走规则
    handleAction() {
      this.actionConditions.forEach((action) => {
        // 循环规则控制字段
        action.mainConditions.forEach((mainCondition) => {
          let judgment = this.judgValue(
            mainCondition.fieldName,
            mainCondition.operator,
            mainCondition.value,
            mainCondition.fieldType
          );
          if (judgment) {
            this.handleCondition(mainCondition, true);
            // 二级规则 - 符合一级规则时才走二级规则
            mainCondition.dynamicPageConditions &&
              mainCondition.dynamicPageConditions.forEach((dynamicPage) => {
                // 二级条件执行
                let judgment = [];
                dynamicPage.twoLevelConditions.forEach((twoLevelCondition) => {
                  let judfment2= this.judgValue(
                      twoLevelCondition.fieldName,
                      twoLevelCondition.operator,
                      twoLevelCondition.value,
                      twoLevelCondition.fieldType
                    );
                    
                  judgment.push(judfment2)
                });
                // 执行高级条件
                if (this.isJudgment(judgment,dynamicPage.label)) {
                  this.handleCondition(dynamicPage, true);
                }
              });
          }
        });
      });

      // 根据规则设置字段显示/隐藏
      this.actionShowFields.forEach((actionShowField) => {
        this.formAttr.forEach((attr) => {
          let fieldId = attr.fieldId;
          if (attr.parentType == "AD" || attr.parentType == "LT") {
            // 地址和地理定位字段时需要对比attr上的父字段
            fieldId = attr.parentId;
          }
          if (fieldId === actionShowField.fieldId) {
            if (actionShowField.show) {
              attr.hidden = false;
            } else {
              attr.hidden = true;
            }
          }
        });
      });

      // 设置部分显示/隐藏
      this.actionShowSections.forEach((actionShowSection) => {
        if (actionShowSection.sectionName) {
          this.formAttr.forEach((attr) => {
            // 控制某部分标题显示/隐藏
            // if (attr.title === actionShowSection.sectionName || attr.groupTitle == actionShowSection.sectionName) {
            if (attr.selectionId === actionShowSection.selectionId) {
              if (actionShowSection.showSection) {
                attr.hidden = false;
                attr.groupShow = true;
              } else {
                // 动态布局下只能设置条件符合时部分显示、字段显示、字段只读、字段必填
                // 如果动态规则条件不成立，原布局部分下有必填字段时不隐藏该部分
                let requiredFields = this.formAttr.find((item) => {
                  return (
                    item.selectionId === actionShowSection.selectionId &&
                    item.required
                  );
                });
                if (requiredFields !== undefined) {
                  attr.hidden = false;
                  attr.groupShow = true;
                } else {
                  attr.hidden = true;
                  attr.groupShow = false;
                }
              }
            }
          });
        }
      });
      // 必须先走字段必填，在走字段只读
      // 根据规则设置字段必填
      this.actionRequireds.forEach((actionRequire) => {
        // 需要判断该字段动态布局是否只读,并且只读条件成立，或者原布局该字段只读
        // 该字段动态布局是否只读,并且只读条件成立
        let flag = this.actionReadonlys.find((item) => {
          return item.fieldId === actionRequire.fieldId && item.readonlys;
        });
        this.formAttr.forEach((attr) => {
          let fieldId = attr.fieldId;
          if (attr.parentType == "AD" || attr.parentType == "LT") {
            // 地址和地理定位字段时需要对比attr上的父字段
            fieldId = attr.parentId;
          }
          if (fieldId === actionRequire.fieldId) {
            // 判断该字段动态布局或者原布局是否只读
            if (flag == undefined && attr.editCopy) {
              // 只读不成立
              // 判断动态必填或者原布局必填是否成立
              if (attr.requiredCopy || actionRequire.required) {
                attr.rules = [
                  {
                    required: true,
                    message: `${this.$i18n.t("label_tabpage_placeenterz")} ${
                      attr.fieldLabel
                    }`,
                    trigger: "blur",
                    // 请输入
                  },
                ];
                // 改变只读,设置可编辑
                attr.edit = true;
              } else {
                // 该字段无规则
                // 清空必填，设置可编辑
                attr.edit = true;
                attr.rules = [];
              }
            }
          }
        });
      });
      //                 控制字段只读
      this.actionReadonlys.forEach((actionReadonly) => {
        this.formAttr.forEach((attr) => {
          let fieldId = attr.fieldId;
          if (attr.parentType == "AD" || attr.parentType == "LT") {
            // 地址和地理定位字段时需要对比attr上的父字段
            fieldId = attr.parentId;
          }
          if (fieldId === actionReadonly.fieldId) {
            // 原字段只读或者动态规则只读条件成立
            if (!attr.editCopy || actionReadonly.readonlys) {
              attr.edit = false;
              attr.rules = [];
            } else {
              // 只读不成立，判断原布局或动态布局必填
              let flag = this.actionRequireds.find((item) => {
                return item.fieldId === actionReadonly.fieldId && item.required;
              });
              if (attr.requiredCopy || flag !== undefined) {
                attr.rules = [
                  {
                    required: true,
                    message: `${this.$i18n.t("label_tabpage_placeenterz")} ${
                      attr.fieldLabel
                    }`,
                    trigger: "blur", // 请输入
                  },
                ]; // 改变只读,设置可编辑
                attr.edit = true;
              } else {
                // 该字段没有规则
                attr.edit = true;
                attr.rules = [];
              }
            }
          }
        });
      });
      this.$refs.infoForm && this.$refs.infoForm.resetRules();
    },
    // 判断值
    judgValue(field, operator, value, fieldType) {
      //  
      if (
        this.$refs.baseForm &&
        (this.$refs.baseForm.formData[field] || this.$refs.baseForm.formData[field] === 0)
      ) {
        if (fieldType === "Q") {
          //  以后组件值是数组的都要走这里
          //  多选列表选中的值
          let valueAry = this.$refs.baseForm.formData[field];
          // 主字段设置的数组
          let actionAry = value.split(",");
          // 多选列表选中值中被主字段设置的数据包含的值
          let filterAry = actionAry.filter((item) => {
            return valueAry.indexOf(item) > -1;
          });
          switch (operator) {
            case "e":
              // 等于(全包含，并且数组长度都一样)
              return (
                filterAry.length === actionAry.length &&
                filterAry.length === valueAry.length
              );
            case "n":
              // 不等于
              return !(
                filterAry.length === actionAry.length &&
                filterAry.length === valueAry.length
              );

            case "c":
              // 包含（只要有其中一个条件就成立）
              return filterAry.length > 0;
            case "k":
              // 不包含
              return filterAry.length === 0;
          }
        } else {
          // 判断字段类型
          fieldType = fieldType ? INPUTTYPE[fieldType] : fieldType;
          // 获取表单中该字段的值
          let formDateField = this.$refs.baseForm.formData[field].toString();

          if (fieldType === "date" || fieldType === "datetime") {
            // 处理日期、日期时间字段
            formDateField = new Date(formDateField).getTime().toString();
            value = new Date(value).getTime().toString();
          }

          if (fieldType === "time") {
            // 处理时间字段
            let formField = formDateField.split(":");
            let valueField = value.split(":");
            formDateField = (
              Number(formField[0] ? formField[0] : "") * 3600 +
              Number(formField[1] ? formField[1] : "") * 60 +
              Number(formField[2] ? formField[2] : "")
            ).toString();

            value = (
              Number(valueField[0] ? valueField[0] : "") * 3600 +
              Number(valueField[1] ? valueField[1] : "") * 60 +
              Number(valueField[2] ? valueField[2] : "")
            ).toString();
          }
          switch (operator) {
            case "e":
              // 等于
              return formDateField === value;
            case "n":
              // 不等于
              return formDateField !== value;
            case "l":
              // 小于
              return Number(formDateField) < Number(value);
            case "g":
              // 大于
              return Number(formDateField) > Number(value);
            case "m":
              // 小于或等于
              return Number(formDateField) <= Number(value);
            case "h":
              // 大于或等于
              return Number(formDateField) >= Number(value);
            case "c":
              // 包含
              return formDateField.includes(value);
            case "k":
              // 不包含
              return !formDateField.includes(value);
            case "s":
              // 起始字符
              return formDateField.indexOf(value) === 0;
          }
        }
      } else {
        // 如果是不包含、不等于返回true，其他false
        let flag = operator === "n" || operator === "k";
        // 如果value为空，此时operator是e（等于）返回为true
        if(operator === 'e' && !value && value!== 0){
          flag = true
        }
        return flag;
      }
    },
    // 表单添加属性
    addFormAttr(group, colNum, attr, isChildren, position, parentAttr) {
      if (position !== undefined) {
        this.$set(attr, "position", position);
      }
      // 单独存储备用原布局中的required和edit,动态布局设置规则的时候使用
      attr.requiredCopy = attr.required;
      attr.editCopy = attr.edit;
      if (attr.required && attr.edit) {
        attr.rules = [
          {
            required: attr.required,
            message: `${this.$i18n.t("label_tabpage_placeenterz")} ${
              attr.fieldLabel || attr.label
            }`,
            trigger: "blur",
          },
        ];
      }
      attr.prop =
        isChildren && isChildren !== undefined ? attr.name : attr.apiname;
      if (
        parentAttr &&
        (parentAttr.fieldType == "AD" || parentAttr.fieldType == "LT")
      ) {
        // 如果父字段是地址和地理定位字段，存储父字段fieldId
        attr.parentId = parentAttr.fieldId;
        attr.parentType = parentAttr.fieldType;
      }
      if (attr.fieldType !== "AD" && attr.fieldType !== "LT") {
        this.$set(
          attr,
          "label",
          attr.fieldLabel === undefined ? attr.label : attr.fieldLabel
        );
        this.$set(
          attr,
          "type",
          INPUTTYPE[attr.fieldType === undefined ? attr.type : attr.fieldType]
        );
        // 百分数字段字数长度限制
        if (attr.fieldType === "P") {
          attr.precision = Number(attr.decimalPlaces);
          let decimal =
            attr.decimalPlaces !== undefined && attr.decimalPlaces !== null
              ? 1 / Math.pow(10, Number(attr.decimalPlaces))
              : 0;
          if (attr.fieldLength !== undefined) {
            attr.valueInterval = {
              min:
                0 -
                Math.pow(10, attr.fieldLength - attr.decimalPlaces - 1) +
                decimal,
              max:
                Math.pow(10, attr.fieldLength - attr.decimalPlaces - 1) -
                decimal,
            };
          } else {
            attr.valueInterval = {
              min:
                0 -
                Math.pow(10, attr.length - attr.decimalPlaces - 1) +
                decimal,
              max: Math.pow(10, attr.length - attr.decimalPlaces - 1) - decimal,
            };
          }
        }
        // 图片显示
        if (
          attr.fieldType === "IMG" &&
          attr.expressionType !== "url" &&
          attr.value !== undefined &&
          this.operation === "EDIT"
        ) {
          if (attr.value !== "") {
            attr.data = [
              {
                name: "",
                url: `${this.userUrl}/querysome.action?m=viewImg&fileid=${attr.value}&binding=${this.token}`,
              },
            ];
          } else {
            attr.data = [];
          }
        }
        // 评分+1
        if (attr.type === "score") {
          attr.value =
            this.operation === "EDIT" ? Number(attr.value) : Number(attr.value);
        }
        if (attr.type === "select" || attr.type === "multi-select") {
          let options = [];
          attr.data &&
            attr.data.length > 0 &&
            attr.data.forEach((item) => {
              options.push({
                key: item.value,
                val: item.id,
              });
            });
          //记录类型
          this.optionList[attr.prop] = options;
          if (attr.prop === "recordtype") {
            this.optionList[attr.prop] = [
              {
                key: attr.value,
                val: attr.id,
              },
            ];
          }
          if (attr.type === "multi-select") {
            // "--无--"
            attr.value =
              attr.value === this.$i18n.t("select.nullvalue") ||
              attr.value === ""
                ? []
                : attr.value.split(";");
          }
        } else if (
          attr.type === "remote-select" ||
          attr.type === "remote-multi-select"
        ) {
          // 查找多选字段选项设置
          if (
            attr.fieldType === "MR" &&
            attr.value.length !== 0 &&
            attr.data &&
            attr.data.length > 0 &&
            attr.data[0].value !== ""
          ) {
            let multiOptions = [];
            let labels = attr.data[0].value.split(";");
            let values = attr.data[0].id.split(";");

            for (let index = 0; index < labels.length; index++) {
              multiOptions.push({
                label: labels[index],
                value: values[index],
              });
            }
            this.$set(this.optionList, attr.prop, multiOptions);
            // "--无--"
            attr.value =
              attr.value === this.$i18n.t("select.nullvalue") ||
              attr.data[0].value === ""
                ? []
                : attr.value.split(";");
          } else {
            // 查找字段选项设置
            let multiOptions = [];
            // 查找字段对应的记录有对应的value时才展示
            if (
              attr.value !== "" &&
              attr.data &&
              attr.data.length > 0 &&
              attr.data[0].value !== ""
            ) {
              multiOptions.push({
                label: attr.data[0].value,
                value: attr.data[0].id,
              });
            } else {
              attr.value = attr.fieldType === "MR" ? [] : "";
            }
            // 创建人和最后修改人选项
            if (
              attr.apiname === "createbyid" ||
              attr.apiname === "lastmodifybyid"
            ) {
              multiOptions.push({
                value: attr.value,
                label: attr[`${attr.apiname}Name`],
              });
            }
            this.$set(this.optionList, attr.prop, multiOptions);
          }
          attr.readonly = true;
        } else if (attr.type === "number") {
          if (Number(attr.decimalPlaces) !== 0) {
            attr.precision = Number(attr.decimalPlaces);
          }
          let decimal =
            attr.decimalPlaces !== undefined && attr.decimalPlaces !== null
              ? 1 / Math.pow(10, Number(attr.decimalPlaces))
              : 0;
          if (attr.fieldLength !== undefined) {
            attr.valueInterval = {
              min:
                0 -
                Math.pow(10, attr.fieldLength - attr.decimalPlaces - 1) +
                decimal,
              max:
                Math.pow(10, attr.fieldLength - attr.decimalPlaces - 1) -
                decimal,
            };
          } else {
            attr.valueInterval = {
              min:
                0 -
                Math.pow(10, attr.length - attr.decimalPlaces - 1) +
                decimal,
              max: Math.pow(10, attr.length - attr.decimalPlaces - 1) - decimal,
            };
          }
        } else if (attr.type === "checkbox") {
          attr.value = attr.value === "true" ? true : false;
        }
        attr.width = colNum === 1 ? "100%" : "50%";
        attr.groupTitle = group.title;
        attr.selectionId = group.selectionId;
        attr.groupShow = true;
        if (
          this.operation === "DETAIL" ||
          (this.operation === "EDIT" && attr.apiname !== "cloudcctag")
        ) {
          this.formAttr.push(attr);
        }
      } else if (this.operation === "DETAIL") {
        this.$set(
          attr,
          "label",
          attr.fieldLabel === undefined ? attr.label : attr.fieldLabel
        );
        this.$set(
          attr,
          "type",
          INPUTTYPE[attr.fieldType === undefined ? attr.type : attr.fieldType]
        );
        attr.width = colNum === 1 ? "100%" : "50%";
        attr.groupTitle = group.title;
        attr.selectionId = group.selectionId;
        attr.groupShow = true;
        this.formAttr.push(attr);
      } else {
        // 地址和地理定位特殊处理
        attr.children.forEach((item) => {
          this.addFormAttr(group, colNum, item, true, position, attr);
          // 判断如果有 国家或地区(以详细地址字段名+00结尾) 字段,获取国家或地区选项
          if (item["name"].slice(item["name"].length - 2) === "00") {
            this.getCountrySelectValue(item["name"]);
          }
        });
      }
      if (attr.isContrField) {
        let changeEvent =
          attr.changeEvent === undefined
            ? "control"
            : `${attr.changeEvent},control`;
        this.$set(attr, "changeEvent", changeEvent);
      }
    },
    // 获取国家或地区选项列表值
    getCountrySelectValue(filedName) {
      CommonObjApi.getSelectValue({ fieldId: "country" }).then((res) => {
        if (res.result && res.returnCode === "1") {
          let options = [];
          res.data.forEach((item) => {
            options.push({
              val: item.codevalue,
              key: item.codekey,
            });
          });
          this.$set(this.optionList, filedName, options);
        } else {
          this.$message.error(res.returnInfo);
        }
      });
    },
    imgSrc(id) {
      return `${this.$cookies.get(
        "domainName"
      )}/querysome.action?m=viewImg&fileid=${id}&binding=${this.token}`;
    },
  },
};
</script>
<style>
@media print {
  body {
    height: 100%;
    overflow :visible !important;
    -webkit-print-color-adjust: exact;
    -moz-print-color-adjust: exact;
    -ms-print-color-adjust: exact;
    print-color-adjust: exact;
  }
  button {
    display: none;
  }
  table tr td:last-child {
    padding-right: 10px !important;
  }
  .print_btn {
    display: none;
  }
}
</style>
<style lang="scss" rel="stylesheet/scss" scoped>
::v-deep .el-dialog__wrapper {
  z-index: 10001 !important;
}
::v-deep .el-dialog__body {
  padding: 2px 20px 40px 20px;
}
::v-deep .el-dialog__header {
  padding: 16px 20px 0 20px;
  border: 0;
  .el-dialog__title {
    font-size: 14px;
  }
}
.printTest {
  margin: 10px;
}
.print_btn {
  position: absolute;
  cursor: pointer;
  top: 22px;
  right: 13px;
  &:hover {
    color: #006dcc;
  }
}
//表单自定义样式
.info_box {
  ::v-deep .infoItem {
    border-bottom: 0;
    margin-top: 6px;
    font-size: 12px;
    color: #080707 !important;
  }
  ::v-deep .cursor-pointer,
  ::v-deep .groupTitle {
    cursor: auto;
    pointer-events: none;
  }
  ::v-deep .el-form-item__label {
    width: 200px;
  }
  ::v-deep .stars {
    width: 55% !important;
  }
  ::v-deep a {
    pointer-events: none;
  }
  ::v-deep .allow-click {
    color: #080707;
  }
  ::v-deep .groupTitle font {
    color: #080707;
  }
}
.tit {
  p {
    height: 18px;
    font-size: 12px;
    font-weight: 500;
    color: #333333;
    line-height: 21px;
    margin: 0 0 5px 0;
  }
  span {
    height: 20px;
    font-size: 18px;
    font-weight: bold;
    color: #333333;
    line-height: 30px;
  }
}
.print_table {
  padding: 10px;
  border: 1px solid #ccc;
  margin-top: 40px;
  box-sizing: border-box;
  border-top: 2px solid #999999;
  .noth_box {
    font-size: 14px;
    padding: 10px;
    height: 40px;
    border: 1px solid #dddbda;
  }
  p {
    height: 16px;
    font-size: 14px;
    font-weight: bold;
    color: #333333;
    line-height: 24px;
    margin-bottom: 15px;
  }
  table {
    border-collapse: collapse;
    tr {
      .tit {
        font-size: 14px;
        font-weight: bold;
        color: #333333;
      }
      th {
        height: 40px;
        background: #f3f2f2;
        padding: 0 14px;
        box-sizing: border-box;
      }
      td {
        height: 40px;
        padding: 0 14px;
        box-sizing: border-box;
        .cannotEdit {
          width: 70%;
          text-align: center;
          img {
            width: 50%;
          }
          // 评分字段显示
          .stars {
            list-style: none;
            margin: 0;
            word-break: break-all;
            color: #ccc;
            float: left;
            display: contents;
          }

          .stars span {
            font-size: 20px;
            margin-left: 3px;
          }

          .stars span:after {
            content: "☆";
          }

          .stars .show:after,
          .stars .show2:after {
            content: "★";
            color: rgb(247, 186, 42);
          }
        }
      }
      td:last-child {
        padding-right: 10px;
      }
    }
  }
}
</style>
