var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"printTest",staticClass:"printTest"},[_c('span',{staticClass:"print_btn",on:{"click":_vm.printBtn}},[_vm._v(" "+_vm._s(_vm.$t("label.print"))+" ")]),_c('div',[_c('div',{staticClass:"tit"},[_c('p',{staticClass:"tit_p"},[_vm._v(_vm._s(_vm.tabName))]),_c('span',[_vm._v(_vm._s(_vm.bigTitle[0] ? _vm.bigTitle[0].fieldValue : ""))])]),(!_vm.loadingDetail)?_c('info-form',{ref:"infoForm",staticClass:"info_box",attrs:{"objId":_vm.objId,"operation":_vm.operation,"option-list":_vm.optionList,"inlineedit":_vm.inlineedit,"is-edit":_vm.isEdit,"canEditOwner":_vm.canEditOwner,"form-attr":_vm.formAttr,"label-position":_vm.labelPosition,"label-width":_vm.labelWidth,"status-icon":_vm.statusIcon,"objectApi":_vm.objectApi}}):_vm._e(),_vm._l((_vm.objArr),function(each,index){return _c('div',{key:index,staticClass:"print_table"},[_c('p',[_vm._v(_vm._s(each.objLabel))]),(
          _vm.historyData.length !== 0 &&
          each.objName === 'tp_sys_approval_instance_his'
        )?_c('div',[_c('table',{staticStyle:{"width":"100%"},attrs:{"bordercolor":"#DDDBDA"}},[_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t("date")))]),_c('th',[_vm._v(_vm._s(_vm.$t("label.state")))]),_c('th',[_vm._v(_vm._s(_vm.$t("label.assigned")))]),_c('th',[_vm._v(_vm._s(_vm.$t("label.layout.realityapprover")))]),_c('th',[_vm._v(_vm._s(_vm.$t("label.note")))]),_c('th',[_vm._v(_vm._s(_vm.$t("label.wholestate")))])]),_vm._l((_vm.historyData),function(item,index1){return _c('tbody',{key:index1},[_c('tr',[_c('td',{staticClass:"tit",attrs:{"colspan":"5"}},[_vm._v(" "+_vm._s(_vm.$t("step"))+":"+_vm._s(item.stepName)+" ")]),_c('td',{staticClass:"tit"},[(item.status === 'Started')?_c('b',[_vm._v(_vm._s(_vm.$t("label.submitted")))]):(item.status === 'Approved')?_c('b',[_vm._v(_vm._s(_vm.$t("label.approved")))]):(
                    item.status === 'Pending' || item.status === 'pending'
                  )?_c('b',[_vm._v(_vm._s(_vm.$t("label.pending")))]):(item.status === 'Recalled')?_c('b',[_vm._v(_vm._s(_vm.$t("lebel.alreadyback")))]):(item.status === 'Rejected')?_c('b',[_vm._v(_vm._s(_vm.$t("label.refused")))]):_vm._e()])]),_vm._l((item.instancehis),function(val,i){return _c('tr',{key:i},[_c('td',[_vm._v(_vm._s(val.lastModifyDate))]),_c('td',[(val.stepStatus === 'Started')?_c('span',[_vm._v(_vm._s(_vm.$t("label.submitted")))]):(val.stepStatus === 'Approved')?_c('span',[_vm._v(_vm._s(_vm.$t("label.approved")))]):(val.stepStatus === 'Pending')?_c('span',[_vm._v(_vm._s(_vm.$t("label.pending")))]):(val.stepStatus === 'Recalled')?_c('span',[_vm._v(_vm._s(_vm.$t("label.pending")))]):(val.stepStatus === 'Rejected')?_c('span',[_vm._v(_vm._s(_vm.$t("label.refused")))]):_vm._e()]),_c('td',[_vm._v(_vm._s(val.assigntoName))]),_c('td',[_vm._v(_vm._s(val.actualName))]),_c('td',[_vm._v(_vm._s(val.comments))]),_vm._m(0,true)])})],2)})],2)]):_vm._e(),(
          _vm.pricebookArr.length > 0 &&
          each.objLabel === _vm.$t('lable.opppdt.selPbk.pricebook')
        )?_c('div',[_c('table',{staticStyle:{"width":"100%"},attrs:{"border":"1","bordercolor":"#DDDBDA"}},[_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t("label.product.pricebooks.name")))]),_c('th',[_vm._v(_vm._s(_vm.$t("lable.product.plist")))]),_c('th',[_vm._v(_vm._s(_vm.$t("lable.product.pricebooks.ustand")))]),_c('th',[_vm._v(_vm._s(_vm.$t("label.inusing")))])]),_vm._l((_vm.pricebookArr),function(item,index1){return _c('tr',{key:index1},[_c('td',[_vm._v(_vm._s(item.pricebookname))]),_c('td',[_vm._v(_vm._s(item.listPrice && parseInt(item.listPrice).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,"))+_vm._s(item.currency))]),_c('td',[_c('el-checkbox',{attrs:{"value":item.useStdPrice === 'true' ? true : false,"disabled":""}})],1),_c('td',[_c('el-checkbox',{attrs:{"value":item.isenable === 'true' ? true : false,"disabled":""}})],1)])})],2)]):_vm._e(),(
          _vm.caseTeamMemberArr.length > 0 &&
          each.objLabel === _vm.$t('c1080')
        )?_c('div',[_c('caseteam',{attrs:{"recordId":_vm.id,"detailType":"printDetail"}})],1):_vm._e(),(
          each.data &&
          each.data.length !== 0 &&
          each.objName !== 'tp_sys_approval_instance_his' &&
          !each.data[0].norecord
        )?_c('div',[_c('table',{staticStyle:{"width":"100%"},attrs:{"border":"1","bordercolor":"#DDDBDA"}},[_c('tr',_vm._l((each.fieldList),function(val,ind){return _c('th',{key:ind,staticStyle:{"min-width":"90px"}},[_vm._v(" "+_vm._s(val.label)+" ")])}),0),_vm._l((each.data),function(item1,index1){return _c('tr',{key:index1},_vm._l((each.fieldList),function(val,ind){return _c('td',{key:ind},[(
                  val.type === 'Y' ||
                  val.type === 'L' ||
                  val.type === 'Q' ||
                  val.type === 'MR' ||
                  val.type === 'M' ||
                  val.type === 'R'
                )?_c('span',[_vm._v(_vm._s(item1[val.name + "ccname"] ? item1[val.name + "ccname"] : item1[val.name]))]):(val.type === 'B')?_c('span',[_c('el-checkbox',{attrs:{"value":item1[val.name] === 'true' || item1[val.name] === 1,"disabled":""}})],1):(val.type === 'D')?_c('span',[_vm._v(" "+_vm._s(_vm._f("dateFormat")(item1[val.name],_vm.countryCode))+" ")]):(val.type === 'F')?_c('span',[_vm._v(" "+_vm._s(_vm._f("datetimeFormat")(item1[val.name],_vm.countryCode))+" ")]):(val.type === 'IMG')?_c('span',{staticClass:"cannotEdit"},[(
                    item1[val.name] !== undefined &&
                    item1[val.name] !== '' &&
                    item1[val.name] !== null
                  )?_c('img',{attrs:{"src":_vm.imgSrc(item1[val.name])}}):_vm._e()]):(val.type === 'SCORE')?_c('span',{staticClass:"cannotEdit stars"},_vm._l((Number(val.length) + 1),function(star,idx){return _c('span',{directives:[{name:"show",rawName:"v-show",value:(idx !== 0),expression:"idx !== 0"}],key:idx,class:{
                    show2: idx <= Number(item1[val.name]),
                  }})}),0):(val.name === 'operate' || val.name === 'clxx')?_c('span',{domProps:{"innerHTML":_vm._s(item1[val.name])}}):_c('span',[_vm._v(_vm._s(item1[val.name]))])])}),0)})],2)]):_vm._e(),(
          (each.data &&
            each.data.length == 0 &&
            each.objName !== 'tp_sys_approval_instance_his') ||
          (each.data && each.data[0].norecord)
        )?_c('div',{staticClass:"noth_box"},[_vm._v(" "+_vm._s(_vm.$t("label.norecord"))+" ")]):_vm._e(),(
          each.objName === 'tp_sys_approval_instance_his' &&
          _vm.historyData.length === 0
        )?_c('div',{staticClass:"noth_box"},[_vm._v(" "+_vm._s(_vm.$t("label.norecord"))+" ")]):_vm._e(),(
          each.objLabel === _vm.$t('lable.opppdt.selPbk.pricebook') &&
          _vm.pricebookArr.length === 0
        )?_c('div',{staticClass:"noth_box"},[_vm._v(" "+_vm._s(_vm.$t("label.norecord"))+" ")]):_vm._e(),(
          each.objLabel === _vm.$t('c1080') &&
          _vm.caseTeamMemberArr.length === 0
        )?_c('div',{staticClass:"noth_box"},[_vm._v(" "+_vm._s(_vm.$t("label.norecord"))+" ")]):_vm._e()])})],2)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',[_c('span')])}]

export { render, staticRenderFns }